<div class="main-header bg-lightdark">
  <div class="logo mr-4">
    <a routerLink="dashboard/v1">
      <img
        src="../../../../../../assets/images/darklogo.png"
        class="logo-img bg-logo radius p-2"
        alt=""
      />
    </a>
  </div>

  <div class="header-part-right">
    <div class="menu-toggle" (click)="toggelSidebar()">
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div ngbDropdown placement="bottom-end" class="ml-2">
      <div class="d-none d-vsm-block">
        <button
          ngbDropdownToggle
          class="btn-outline-light radius btn header-dropdown-item"
        >
          <span class="text-white text-small">
            {{ user.firstName }} {{ user.lastName }}
          </span>
        </button>

        <div
          ngbDropdownMenu
          aria-labelledby="userDropdown"
          class="header-account-dropdown mt-1 bg-dark radius"
        >
          <button
            class="dropdown-item align-items-center text-white bg-icon d-flex justify-content-between"
            (click)="signout()"
          >
            <span class="d-flex">
              <i
                class="i-Close mr-1"
                style="font-size: 18px; color: #f3f4f6"
              ></i>
            </span>
            <span class="text-small"> خروج </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

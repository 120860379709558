<div class="bg"></div>

<div class="full-page">
  <div class="card col-6">
    <div id="notfound">
      <div class="notfound">
        <div class="notfound-404">
          <h1>404</h1>
        </div>
        <h2>صفحه مورد نظر پیدا نشد</h2>
        <p>
          صفحه ای که دنبالش هستید پاک شده یا موجود نیست یا به صورت موقت قابل
          دسترس نیست <a href="#">برگشت به خانه</a>
        </p>
        <div class="notfound-social">
          <a href="#"><i class="i-Facebook-2"></i></a>
          <a href="#"><i class="i-Linkedin-2"></i></a>
          <a href="#"><i class="i-Email"></i></a>
          <a href="#"><i class="i-Instagram"></i></a>
        </div>
      </div>
    </div>
  </div>
</div>

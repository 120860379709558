<router-outlet
  ><ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#47489b"
    type="square-jelly-box"
    [fullScreen]="true"
    ><p class="text-white pt-5">درحال بارگذاری...</p></ngx-spinner
  ></router-outlet
>

<div class="app-footer">
  <div
    class="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center"
  >
    <span class="flex-grow-1"></span>
    <div class="d-flex align-items-center">
      <img class="logo" src="./assets/images/logo.jpg" alt="" />
      <div>
        <p class="m-0">&copy; 2022 Irani.Expert Admin</p>
        <p class="m-0">
          کلیه حقوق مادی و معنوی این سیستم متعلق به Irani.Expert می باشد.
        </p>
      </div>
    </div>
  </div>
</div>

<div
  [ngClass]="{ open: navService.sidebarState.sidenavOpen }"
  class="sidebar-left rtl-ps-none rounded-4 bg-lightdark shadow-lg my-webKit"
  style="overflow-y: auto"
>
  <div class="logo">
    <img src="./assets/images/logo.jpg" alt="" />
  </div>
  <ul class="navigation-left">
    <ng-container *ngFor="let item of nav">
      <ng-container *ngIf="item.name !== '*'">
        <li
          [ngClass]="{ active: item.active }"
          (click)="onClickChangeActiveFlag(item)"
          class="nav-item lvl1"
          *appCanAccess="item.privilege"
        >
          <a
            class="nav-item-hold d-block"
            routerLink="{{ item.state }}"
            (click)="closeChildNav()"
            *ngIf="!item.disabled && item.navType === 'link'"
          >
            <i class="text-white" [class]="'nav-icon  ' + item.icon"></i>
            <span class="nav-text text-white p-1">{{ item.name }}</span>
          </a>
          <a
            class="nav-item-hold d-block"
            (mouseenter)="selectItem(item)"
            *ngIf="!item.disabled && item.navType === 'dropDown'"
          >
            <i class="text-white" [class]="'nav-icon ' + item.icon"></i>
            <span class="nav-text text-white">{{ item.name }}</span>
          </a>
          <div
            class="nav-item-hold"
            appCanAccess="''"
            *ngIf="!item.disabled && item.navType === 'extLink'"
          >
            <a [href]="item.state" target="_blank">
              <i class="text-white" [class]="'nav-icon' + item.icon"></i>
              <span class="nav-text text-white">{{ item.name }}</span>
            </a>
          </div>
          <div class="triangle" *ngIf="!item.disabled"></div>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>

<div
  [ngClass]="{ open: navService.sidebarState.childnavOpen }"
  class="sidebar-left-secondary rtl-ps-none bg-dark rounded-4"
  [perfectScrollbar]="{
    suppressScrollX: true
  }"
  (mouseleave)="closeChildNav()"
>
  <header>
    <h6>برنامه ها</h6>
  </header>
  <ng-container
    *ngTemplateOutlet="
      menuTemplate;
      context: { parentItem: navService.selectedItem }
    "
  ></ng-container>
</div>

<ng-template class="bg-dark" #menuTemplate let-parentItem="parentItem">
  <div class="">
    <ul class="childNav" appDropdown>
      <li *ngFor="let item of parentItem?.sub" appDropdownLink class="nav-item">
        <ng-container *appCanAccess="item.privilege">
          <a
            routerLink="{{ item.state }}"
            *ngIf="item.navType === 'link'"
            class=""
            routerLinkActive="open"
          >
            <i
              class="text-white"
              *ngIf="item.icon"
              [class]="'nav-icon ' + item.icon"
            ></i>
            <span class="item-name lvl1 text-white">{{ item.name }}</span>
          </a>
          <div *ngIf="item.navType === 'dropDown'">
            <a
              appDropdownToggle
              [ngClass]="{ open: item.active }"
              routerLinkActive="open"
            >
              <i
                class="text-white"
                *ngIf="item.icon"
                [class]="'nav-icon ' + item.icon"
              ></i>
              <span class="item-name text-white">{{ item.name }}</span>
              <i class="dd-arrow i-Arrow-Down"></i>
            </a>
            <ng-container
              *ngTemplateOutlet="menuTemplate; context: { parentItem: item }"
            ></ng-container>
          </div>
        </ng-container>
      </li>
    </ul>
  </div>
</ng-template>

<div
  [ngClass]="{ open: navService.sidebarState.childnavOpen }"
  class="sidebar-overlay"
  (click)="closeChildNav()"
></div>
